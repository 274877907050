<template>
  <!-- :key on v-card is necessary for animations -->
  <v-card
    :key="isEditMode"
    class="d-flex flex-column"
    :class="{ 'edit-mode': isEditMode }"
    :style="cssVars"
    outlined
  >
    <form @submit.prevent="saveTopic">
      <v-card-title
        class="d-flex flex-nowrap align-start break-words"
        :class="{ 'flex-column-reverse align-end': isEditMode && $vuetify.breakpoint.xs }"
      >
        <v-text-field
          v-if="isEditMode"
          v-model="editData.name"
          :class="{ 'w-50': $vuetify.breakpoint.smAndUp, 'w-full mt-4': $vuetify.breakpoint.xs}"
          :placeholder="$t('topics.list.card.name-placeholder')"
          tabindex="1"
          hide-details="auto"
          :rules="rules.name"
          required
          rounded
          filled
          dense
          @click="onClick('Own Topic Title', editData.name)"
        />
        <h4
          v-else
          class="font-weight-regular text--primary"
          :class="{ 'd-none d-md-block': isArchivingTriggered}"
        >
          <v-tooltip
            v-if="!isUserEditable"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- Need to use a wrapper element, otherwise the tooltip won't show up if the button is disabled -->
              <span
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2">
                  mdi-lock
                </v-icon>
              </span>
            </template>
            <span>{{ $t('topics.list.card.actions.edit-disabled') }}</span>
          </v-tooltip>

          {{ name }}

          <v-badge
            v-if="hasLandingpage"
            :content="$t('topics.list.card.landingpage-badge')"
            color="primary"
            :class="isMobile ? '' : 'ml-4'"
            :inline="isMobile"
          />
        </h4>

        <v-spacer />

        <Permission permission="topic:write">
          <div class="button-group">
            <!-- Edit save and cancel buttons -->
            <div v-if="isEditMode">
              <v-btn
                depressed
                tabindex="4"
                class="mr-2"
                :loading="isPerformingAction('canceling')"
                :disabled="isPerformingAction('canceling', true)"
                @click.prevent="leaveEditMode"
              >
                {{ $t('topics.list.card.actions.cancel') }}
              </v-btn>

              <v-btn
                depressed
                type="submit"
                color="primary"
                tabindex="3"
                :loading="isPerformingAction('saving')"
                :disabled="isPerformingAction('saving', true)"
              >
                {{ $t('topics.list.card.actions.save') }}
              </v-btn>
            </div>

            <v-slide-y-transition
              group
              leave-absolute
            >
              <!-- Archiving confirmation buttons -->
              <div
                v-if="isArchivingTriggered"
                key="archive"
                class="archive-buttons"
              >
                <v-btn
                  depressed
                  class="mr-2"
                  @click.prevent="cancelArchiving"
                >
                  {{ $t('topics.list.card.actions.cancel') }}
                </v-btn>

                <v-btn
                  depressed
                  color="primary"
                  tabindex="3"
                  :loading="isPerformingAction('archiving')"
                  :disabled="isPerformingAction('archiving', true)"
                  @click.prevent="archiveTopic"
                >
                  {{ $t('topics.list.card.actions.archive-confirm') }}
                </v-btn>
              </div>

              <!-- Button group for default mode -->
              <div
                v-else-if="!isEditMode"
                key="default"
              >
                <!-- Edit Button -->
                <v-tooltip
                  v-if="!isArchived"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <!-- Need to use a wrapper element, otherwise the tooltip won't show up if the button is disabled -->
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-btn
                        icon
                        color="primary"
                        class="mr-4"
                        :disabled="isPerformingAction('editing', true) || !isUserEditable"
                        @click.prevent="enterEditMode"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span v-if="isUserEditable">{{ $t('topics.list.card.actions.edit') }}</span>
                  <span v-else>{{ $t('topics.list.card.actions.edit-disabled') }}</span>
                </v-tooltip>

                <!-- Archive Button -->
                <v-tooltip
                  v-if="!isArchived"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <!-- Need to use a wrapper element, otherwise the tooltip won't show up if the button is disabled -->
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-btn
                        icon
                        color="primary"
                        :disabled="!isUserEditable"
                        @click.prevent="initiateArchiving"
                      >
                        <v-icon>mdi-archive</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span v-if="isUserEditable">{{ $t('topics.list.card.actions.archive') }}</span>
                  <span v-else>{{ $t('topics.list.card.actions.archive-disabled') }}</span>
                </v-tooltip>

                <!-- Unarchive Button -->
                <v-tooltip
                  v-if="isArchived"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      color="primary"
                      :loading="isPerformingAction('unarchiving')"
                      :disabled="isPerformingAction('unarchiving', true)"
                      @click.prevent="unarchiveTopic"
                      v-on="on"
                    >
                      <v-icon>mdi-archive-arrow-up</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('topics.list.card.actions.unarchive') }}</span>
                </v-tooltip>
              </div>
            </v-slide-y-transition>
          </div>
        </Permission>
      </v-card-title>

      <v-divider />

      <v-card-text class="content text-body-1">
        <v-textarea
          v-if="isEditMode"
          v-model="editData.description"
          :placeholder="$t('topics.list.card.description-placeholder')"
          tabindex="2"
          rows="3"
          max-rows="5"
          :rules="rules.description"
          hide-details="auto"
          auto-grow
          rounded
          filled
          dense
          @click="onClick('Own Topic Description', editData.description)"
        />
        <div
          v-else
          class="description"
        >
          <span
            v-if="description"
            class="line-breaks"
          >
            {{ description }}
          </span>
          <span
            v-else-if="isArchived"
            class="text--secondary"
          >
            {{ $t('topics.list.card.no-description-archived') }}
          </span>
          <span
            v-else
            class="text--secondary font-italic clickable"
            @click="enterEditMode"
          >
            {{ $t('topics.list.card.no-description') }}
          </span>
        </div>
      </v-card-text>
    </form>

    <v-card-actions>
      <v-btn
        text
        color="primary"
        class="ml-2"
        :to="{ name: 'content-creator', query: { topicId: id } }"
      >
        <v-icon left>
          mdi-brain
        </v-icon>
        {{ $t('topics.list.card.actions.content-creator') }}
      </v-btn>
      <v-btn
        v-if="hasLandingpage"
        text
        :to="{ name: 'landingpage-details', params: { slug: landingpage.slug } }"
      >
        <v-icon left>
          mdi-button-pointer
        </v-icon>
        {{ $t('topics.list.card.actions.landingpage-detail') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import I18n from '@/i18n'
import performingActionMixin from '@/mixins/performingAction'
import Permission from '@/components/Permission'
import ARCHIVE_TOPIC from './graphql/archiveTopic.gql'
import UNARCHIVE_TOPIC from './graphql/unarchiveTopic.gql'
import UPDATE_TOPIC from './graphql/updateTopic.gql'
import CREATE_TOPIC from './graphql/createTopic.gql'
import TopicStatus from './TopicStatus'

const MIN_NAME_LENGTH = 5
const MAX_NAME_LENGTH = 100
const MAX_DESCRIPTION_LENGTH = 2000

const i18n = I18n.getInstance()

const requiredRule = (field) => value => (
  Boolean(value) ||
  i18n.t('topics.list.card.rules.required', {
    field: i18n.t(`topics.list.card.rules.${field}-field`)
  })
)
const minRule = (field, min) => value => (
  value.length >= min ||
  i18n.t('topics.list.card.rules.min', {
    field: i18n.t(`topics.list.card.rules.${field}-field`),
    min
  })
)
const maxRule = (field, max) => value => (
  value.length <= max ||
  i18n.t('topics.list.card.rules.max', {
    field: i18n.t(`topics.list.card.rules.${field}-field`),
    max
  })
)

export default {
  components: {
    Permission
  },

  mixins: [
    performingActionMixin
  ],

  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    isNew: {
      type: Boolean,
      default: false
    },
    isSystemGenerated: {
      type: Boolean,
      default: false
    },
    isUserEditable: {
      type: Boolean,
      default: true
    },
    landingpage: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    isEditMode: false,
    isArchivingTriggered: false,
    editData: {
      name: '',
      description: ''
    }
  }),

  computed: {
    cssVars () {
      return {
        '--border-color': this.$vuetify.theme.themes.light.markero.blue
      }
    },

    isArchived () {
      return this.status === TopicStatus.ARCHIVED
    },

    hasLandingpage () {
      return Boolean(this.landingpage?.id)
    },

    rules () {
      return {
        name: [
          requiredRule('name'),
          minRule('name', MIN_NAME_LENGTH),
          maxRule('name', MAX_NAME_LENGTH)
        ],
        description: [
          maxRule('description', MAX_DESCRIPTION_LENGTH)
        ]
      }
    },

    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },

  created () {
    this.isEditMode = this.isNew
  },

  methods: {
    onClick (label, value) {
      this.$tracking.event('Marketing Topics', this.$tracking.trackingEvents.CLICKED, label, value)
    },

    initiateArchiving () {
      this.isArchivingTriggered = true
    },

    cancelArchiving () {
      this.isArchivingTriggered = false
    },

    enterEditMode () {
      const label = this.isSystemGenerated ? 'Topic Edit' : 'Own Topic Edit'
      this.$tracking.event('Marketing Topics', this.$tracking.trackingEvents.CLICKED, label)

      this.isEditMode = true
      this.editData = {
        name: this.name,
        description: this.description
      }
    },

    leaveEditMode () {
      const label = this.isSystemGenerated ? 'Topic Edit Cancel' : 'Own Topic Edit Cancel'
      this.$tracking.event('Marketing Topics', this.$tracking.trackingEvents.CLICKED, label)
      const yes = !this.hasDataChanged() || confirm(this.$t('topics.list.card.cancel-edit-confirm-message'))
      if (yes) {
        this.isEditMode = false
        this.$emit('cancel')
      }
    },

    hasDataChanged () {
      return this.editData.name !== this.name || this.editData.description !== this.description
    },

    async saveTopic () {
      if (this.hasDataChanged()) {
        const label = this.isSystemGenerated ? 'Topic Save' : 'Own Topic Save'
        this.$tracking.event('Marketing Topics', this.$tracking.trackingEvents.CLICKED, label)

        await this.withPerformingAction('saving', async () => {
          if (this.isNew) {
            await this.createTopic()
          } else {
            await this.updateTopic()
          }
        })
      } else if (!this.isNew) {
        this.isEditMode = false
      }
    },

    async createTopic () {
      try {
        await this.mutateTopic(CREATE_TOPIC, {
          input: {
            name: this.editData.name,
            description: this.editData.description
          }
        })

        this.isEditMode = false
      } catch (err) {
        this.handleGraphQLError(err)
      }
    },

    async updateTopic () {
      try {
        await this.mutateTopic(UPDATE_TOPIC, {
          input: {
            id: this.id,
            name: this.editData.name,
            description: this.editData.description
          }
        })

        this.isEditMode = false
      } catch (err) {
        this.handleGraphQLError(err)
      }
    },

    async archiveTopic () {
      const label = this.isSystemGenerated ? 'Topic Archive' : 'Own Topic Archive'
      this.$tracking.event('Marketing Topics', this.$tracking.trackingEvents.CLICKED, label)

      await this.withPerformingAction('archiving', async () => {
        try {
          await this.mutateTopic(ARCHIVE_TOPIC, { id: this.id })
        } catch (err) {
          this.cancelArchiving()
          this.handleGraphQLError(err)
        }
      })
    },

    async unarchiveTopic () {
      const label = this.isSystemGenerated ? 'Topic Reactivation' : 'Own Topic Reactivation'
      this.$tracking.event('Marketing Topics', this.$tracking.trackingEvents.CLICKED, label)

      await this.withPerformingAction('unarchiving', async () => {
        try {
          await this.mutateTopic(UNARCHIVE_TOPIC, { id: this.id })
        } catch (err) {
          this.handleGraphQLError(err)
        }
      })
    },

    /**
     * Generic method to mutate a topic, handling also the cache update.
     */
    async mutateTopic (mutation, variables) {
      return this.$apollo.mutate({
        mutation,
        variables,
        update: (cache, { data: { topic } }) => {
          const event = this.isNew ? 'create' : 'update'
          this.$emit(event, topic, cache)
        }
      })
    },

    handleGraphQLError (err) {
      const graphQLError = err.graphQLErrors[0]
      // First we handle specific errors that we expect.
      if (graphQLError?.extensions.code === 'MAX_ACTIVE_TOPICS_REACHED') {
        this.$emit('error', graphQLError.extensions.code)
      // Otherwise we show the user any message that comes from the server as we cannot further predict the error.
      // It could be a forbidden error though, but that should not arise as long as the frontend is correctly implemented.
      } else {
        this.$emit('error', err.message)
      }
    }
  }
}
</script>

<style scoped>
.edit-mode {
  border-color: var(--border-color);
  border-width: 2px;
}

.button-group,
.archive-buttons {
  display: flex;
  justify-content: flex-end;
}

.button-group {
  min-width: 100px;
}

.archive-buttons {
  min-width: 300px;
}

.clickable {
  cursor: pointer;
}

.description {
  max-width: 800px;
}

.break-words {
  word-break: break-word;
}

.line-breaks {
  white-space: pre-line;
}
</style>
